import React, { useState, useEffect } from "react"
import { Link } from "gatsby";
import { AppBar, Toolbar, styled, useTheme } from '@mui/material'
import queryString from 'query-string'
import {Buffer} from 'buffer';

import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button"
import LogoPiramide from "../../static/logo-piramides.svg"
import LogoOceanica from "../../static/oceanica_original.png"
import admBarStyles from "../Portal/Layout/Piramide/adminNavbarStyle"
import PdfViewer from '../Portal/Views/Digitization/PdfViewer'

import { initAxiosInterceptors } from 'utils/axiosConfig'
import { useDialog } from 'context/DialogContext'
import { useLoading } from 'context/LoadingContext'
const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY

const AppBarStyled = styled(AppBar)(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.appBar,
    ...newStyles.absolute,
  }
})

const Container = styled('div')(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.flex
  }
})

const NewDivContent = styled('div')(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.content
  }
})

const Image = styled('img')(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.logo
  }
})

export default function ReportePage(props) {
  const params = queryString.parse(props.location.search)
  console.log('pramas:',params )
  const loading = useLoading()
  const dialog = useDialog()
  const theme = useTheme()
  const classes = admBarStyles(theme)
  const [apiUrl, setApiUrl] = useState(null)
  const [pdfUrl, setPdfUrl] = useState(null)

  function setInitialApiUrl() {
    if (params.id && params.id !== undefined) {
      setApiUrl(`reports/get/${params.id}`)
    } else if (params.reportRunId && params.reportRunId !== undefined) {
      setApiUrl(`reports/get_url_from_report_run_id/${params.reportRunId}`)
    } else if (params.urlReport && params.urlReport !== undefined) {
      setPdfUrl(Buffer.from(params.urlReport, 'base64').toString('utf-8'))
    }
  }

  useEffect(() => {
    initAxiosInterceptors(dialog, loading)
    setInitialApiUrl()
  }, [])

  return (
    <NewDivContent>
      <AppBarStyled>
        <Toolbar sx={classes.container}>
          <Container>
            <Button className={classes.button__logo} color="transparent">
              {(insuranceCompany === 'OCEANICA')
                ? <Link to="/"><Image src={LogoOceanica} alt="Oceánica de Seguros" /></Link>
                : <Link to="/"><Image src={LogoPiramide} alt="Pirámide Seguros" /></Link>
              }
            </Button>
          </Container>
        </Toolbar>
      </AppBarStyled>
      {(apiUrl || pdfUrl) && <PdfViewer apiUrl={apiUrl} pdfUrl={pdfUrl} />}
    </NewDivContent>
  );
}
